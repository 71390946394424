import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Icon from 'src/components/UI/MarkdownIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_SM } from 'src/utils/breakpoints'

import textPT from '../../assets/data/_ficouComAlgumaDuvida/textPT.json'
import textEN from '../../assets/data/_ficouComAlgumaDuvida/textEN.json'

import usePageQuery from '../../pageQuery'
import { FicouComAlgumaDuvidaSection, BlogCarousel } from './style'
interface IDataJson {
  image: string;
  primaryTag: string;
  title: string;
  data: string;
  description: string;
  link: string;
  alt: string;
}

const FicouComAlgumaDuvida = (props: {lang: string}) => {
  const pageText = props.lang === 'pt' ? textPT : textEN
  const data = usePageQuery()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <FicouComAlgumaDuvidaSection className='padding-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-center fw-600 text-grayscale--500'>
              {pageText.title}
            </h3>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-center text-grayscale--500'>
              <a
                className='fw-700 text-range--extra'
                href='https://blog.inter.co/'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_11',
                    element_name: 'Acompanhe nosso blog e confira os conteúdos que preparamos para simplificar sua vida.',
                    element_action: 'click button',
                    section_name: pageText.title,
                    redirect_url: 'https://blog.inter.co/',
                  })
                }}
                dangerouslySetInnerHTML={{ __html: pageText.text }}
              />
            </p>
          </div>
          <div className='col-12 px-lg-0'>
            {width <= WIDTH_SM
              ? (
                pageText.BlogData.map((item: IDataJson, index: number) => (
                  <a
                    key={index}
                    data-blog={item.title}
                    href={item.link}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_11',
                        element_name: item.title,
                        element_action: 'click button',
                        section_name: pageText.title,
                        redirect_url: item.link,
                      })
                    }}
                  >
                    <BlogCarousel className='col-12 p-card'>
                      <article className='col-12 px-0'>
                        <Img fluid={data[item.image].fluid} alt={item.alt} />
                        <div className='col-12 pt-3 rounded-5 px-4 mt-n3 bg-white'>
                          <p className='fs-12 lh-15 fw-700 text-white bg-orange--extra rounded-1 mb-1 px-1 py-1 mr-2 primary-tag'>{item.primaryTag}</p>
                          <p className='fs-20 lh-25 fw-600 mb-2 mt-xl-2 font-sora text-grayscale--500 mt-0 pt-3'>{item.title}</p>
                          <span className='fs-12 lh-15 fw-700 d-block text-grayscale--300'>{item.data}</span>
                          <a
                            data-blog={item.title}
                            href={item.link}
                            target='_blank'
                            rel='noreferrer'
                            title={`Leia mais em ${item.link}`}
                            className='fs-14 lh-17 fw-400 text-orange--extra text-right d-none d-lg-block'
                          >
                            Leia mais
                            <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                          </a>
                        </div>
                      </article>
                    </BlogCarousel>
                  </a>
                  ))
                  )
                : (
                  <DefaultCarousel
                    md={{ items: 2, partialVisibilityGutter: 10 }}
                    lg={{ items: 3 }}
                    xl={{ items: 3 }}
                  >
                    { pageText.BlogData.map((item: IDataJson, index: number) => (
                      <a
                        key={index}
                        data-blog={item.title}
                        href={item.link}
                        target='_blank'
                        rel='noreferrer'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_09',
                            element_name: item.title,
                            element_action: 'click button',
                            section_name: pageText.title,
                            redirect_url: item.link,
                          })
                        }}
                      >
                        <BlogCarousel className='col-12 p-card'>
                          <article className='col-12 px-0'>
                            <Img fluid={data[item.image].fluid} alt={item.alt} />
                            <div className='col-12 pt-3 rounded-5 px-4 mt-n3 bg-white'>
                              <p className='fs-12 lh-15 fw-700 text-white bg-orange--extra rounded-1 mb-1 px-1 py-1 mr-2 primary-tag'>{item.primaryTag}</p>
                              <p className='fs-md-20 lh-md-25 fw-600 mb-2 font-sora text-grayscale--500 mt-0 pt-3'>{item.title}</p>
                              <span className='fs-12 lh-15 fw-700 d-block text-grayscale--300'>{item.data}</span>
                              <p className='fs-lg-16 lh-lg-19 mb-2 fw-400 d-none d-lg-block mt-3 text-grayscale--500'>{item.description}</p>
                              <a
                                data-blog={item.title}
                                href={item.link}
                                target='_blank'
                                rel='noreferrer'
                                title={`Leia mais em ${item.link}`}
                                className='fs-14 lh-17 fw-400 text-orange--extra text-right d-none d-lg-block'
                              >
                                Leia mais
                                <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                              </a>
                            </div>
                          </article>
                        </BlogCarousel>
                      </a>
                    ))
                  }
                  </DefaultCarousel>
                )
            }
          </div>
        </div>
      </div>
    </FicouComAlgumaDuvidaSection>
  )
}

export default FicouComAlgumaDuvida
