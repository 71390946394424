import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import styled from 'styled-components'
import { orange } from 'src/styles/colors'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import textPT from '../../assets/data/_controle/textPT.json'
import textEN from '../../assets/data/_controle/textEN.json'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

type IyourDataJSON = {
  icon?: string;
  list?: string;
  text: string;
}

const ComoExercerSeusDireitos = (props: {lang: string}) => {
  const pageText = props.lang === 'pt' ? textPT : textEN
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const List = styled.div`
    background: ${orange.extra};
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
  `

  return (
    <section className='bg-grayscale--100 py-md-5'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2
              className='fs-24 lh-30 fs-md-40 lh-md-50 fw-600 text-grayscale--500 mb-3 mb-md-4'
              dangerouslySetInnerHTML={{ __html: pageText.title }}
            />
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--500'>{pageText.text}</p>
            {pageText.yourData.map((item: IyourDataJSON, index: number) => (
              <div key={index} className='d-flex align-items-start py-2'>
                <List className='text-white fs-16 lh-20'>
                  {item.icon}
                </List>
                <div className='col-10 pl-2'>
                  <span className='d-block fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--500'>{item.text}</span>
                </div>
              </div>
            ))}
            <p
              className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--500 mt-4 mb-0'
              dangerouslySetInnerHTML={{ __html: pageText.textTwo }}
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_03',
                  section_name: pageText.title,
                  element_action: 'click button',
                  element_name: props.lang === 'pt' ? 'privacidade@inter.co' : '',
                  redirect_url: 'privacidade@inter.co',
                })
              }}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5 order-md-last mt-4 mt-md-0'>
            <ImageWebp
              pathSrc={width <= WIDTH_MD ? pageText.pathSrcMobile : pageText.pathSrcDesktop}
              arrayNumbers={[ 312, 336, 309.08, 456, 456 ]}
              altDescription={pageText.altImage}
              className='float-md-right'
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ComoExercerSeusDireitos
