import React from 'react'

import { Wrapper } from './style'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import Hero from './sections/_hero/_index'
import SeuDireitoAPrivacidade from './sections/_seuDireitoAPrivacidade/_index'
import ComoExercerSeusDireitos from './sections/_como-exercer-seus-direitos/_index'
import ComoMantemosSeguro from './sections/_comoMantemosSeguro/_index'
import EliminamosAsBarreirasPraVoce from './sections/_eliminamosAsBarreirasPraVoce/_index'
import ConhecaNossoEncarregado from './sections/_conhecaNossoEncarregado/_index'
import EstamosSempreMelhorando from './sections/_estamosSempreMelhorando/_index'
import MaisSeguranca from './sections/_maisSeguranca/_index'
import AlgumasDefinicoes from './sections/_algumas-definicoes/_index'
import ConhecaSeusDireitos from './sections/_conheca-seus-direitos/_index'
import FicouComAlgumaDuvida from './sections/_ficouComAlgumaDuvida/_index'
import FAQSection from './sections/_faq/_index'

const PrivacidadeDeDados = () => {
  const lang = 'pt'
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
        <SeuDireitoAPrivacidade lang={lang} />
        <ComoExercerSeusDireitos lang={lang} />
        <ComoMantemosSeguro lang={lang} />
        <EliminamosAsBarreirasPraVoce lang={lang} />
        <ConhecaNossoEncarregado lang={lang} />
        <EstamosSempreMelhorando lang={lang} />
        <MaisSeguranca lang={lang} />
        <AlgumasDefinicoes lang={lang} />
        <ConhecaSeusDireitos lang={lang} />
        <FicouComAlgumaDuvida lang={lang} />
        <FAQSection lang={lang} pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

export default PrivacidadeDeDados
